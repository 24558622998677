import { mailerSendConfig, mailgunConfig, mailjetConfig, mailtrapConfig, mandrillConfig, netCoreConfig, nodemailerConfig, postmarkConfig, sendgridConfig, sendinblueConfig, sesConfig, outlook365Config, infobipEmailConfig, resendConfig, plunkConfig, sparkpostConfig, emailWebhookConfig, } from '../credentials';
import { EmailProviderIdEnum } from '../provider.enum';
import { ChannelTypeEnum } from '../../../types';
export const emailProviders = [
    {
        id: EmailProviderIdEnum.Novu,
        displayName: 'Novu Email',
        channel: ChannelTypeEnum.EMAIL,
        credentials: [],
        docReference: 'https://docs.novu.co/channels-and-providers/default-providers#novu-email-provider',
        logoFileName: { light: 'novu.png', dark: 'novu.png' },
    },
    {
        id: EmailProviderIdEnum.Mailgun,
        displayName: 'Mailgun',
        channel: ChannelTypeEnum.EMAIL,
        credentials: mailgunConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/mailgun',
        logoFileName: { light: 'mailgun.svg', dark: 'mailgun.svg' },
    },
    {
        id: EmailProviderIdEnum.Mailjet,
        displayName: 'Mailjet',
        channel: ChannelTypeEnum.EMAIL,
        credentials: mailjetConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/mailjet',
        logoFileName: { light: 'mailjet.png', dark: 'mailjet.png' },
    },
    {
        id: EmailProviderIdEnum.Mailtrap,
        displayName: 'Mailtrap',
        channel: ChannelTypeEnum.EMAIL,
        credentials: mailtrapConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/mailtrap',
        logoFileName: { light: 'mailtrap.svg', dark: 'mailtrap.svg' },
    },
    {
        id: EmailProviderIdEnum.Mandrill,
        displayName: 'Mandrill',
        channel: ChannelTypeEnum.EMAIL,
        credentials: mandrillConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/mandrill',
        logoFileName: { light: 'mandrill.svg', dark: 'mandrill.svg' },
    },
    {
        id: EmailProviderIdEnum.Postmark,
        displayName: 'Postmark',
        channel: ChannelTypeEnum.EMAIL,
        credentials: postmarkConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/postmark',
        logoFileName: { light: 'postmark.png', dark: 'postmark.png' },
    },
    {
        id: EmailProviderIdEnum.SendGrid,
        displayName: 'SendGrid',
        channel: ChannelTypeEnum.EMAIL,
        credentials: sendgridConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/sendgrid',
        logoFileName: { light: 'sendgrid.png', dark: 'sendgrid.png' },
    },
    {
        id: EmailProviderIdEnum.Sendinblue,
        displayName: 'Sendinblue',
        channel: ChannelTypeEnum.EMAIL,
        credentials: sendinblueConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/sendinblue',
        logoFileName: { light: 'sendinblue.png', dark: 'sendinblue.png' },
    },
    {
        id: EmailProviderIdEnum.SES,
        displayName: 'SES',
        channel: ChannelTypeEnum.EMAIL,
        credentials: sesConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/amazonses',
        logoFileName: { light: 'ses.svg', dark: 'ses.svg' },
    },
    {
        id: EmailProviderIdEnum.NetCore,
        displayName: 'Netcore',
        channel: ChannelTypeEnum.EMAIL,
        credentials: netCoreConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/netcore',
        logoFileName: { light: 'netcore.png', dark: 'netcore.png' },
    },
    {
        id: EmailProviderIdEnum.CustomSMTP,
        displayName: 'Custom SMTP',
        channel: ChannelTypeEnum.EMAIL,
        credentials: nodemailerConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/custom-smtp',
        logoFileName: { light: 'custom_smtp.svg', dark: 'custom_smtp.svg' },
    },
    {
        id: EmailProviderIdEnum.MailerSend,
        displayName: 'MailerSend',
        channel: ChannelTypeEnum.EMAIL,
        credentials: mailerSendConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/mailersend',
        logoFileName: { light: 'mailersend.svg', dark: 'mailersend.svg' },
    },
    {
        id: EmailProviderIdEnum.Outlook365,
        displayName: 'Microsoft Outlook365',
        channel: ChannelTypeEnum.EMAIL,
        credentials: outlook365Config,
        docReference: 'https://docs.novu.co/channels-and-providers/email/outlook365',
        logoFileName: { light: 'outlook365.png', dark: 'outlook365.png' },
    },
    {
        id: EmailProviderIdEnum.Infobip,
        displayName: 'Infobip',
        channel: ChannelTypeEnum.EMAIL,
        credentials: infobipEmailConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/infobip',
        logoFileName: { light: 'infobip.png', dark: 'infobip.png' },
    },
    {
        id: EmailProviderIdEnum.Resend,
        displayName: 'Resend',
        channel: ChannelTypeEnum.EMAIL,
        credentials: resendConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/resend',
        logoFileName: { light: 'resend.svg', dark: 'resend.svg' },
    },
    {
        id: EmailProviderIdEnum.Plunk,
        displayName: 'Plunk',
        channel: ChannelTypeEnum.EMAIL,
        credentials: plunkConfig,
        docReference: 'https://docs.novu.co/channels/email/plunk',
        logoFileName: { light: 'plunk.png', dark: 'plunk.png' },
    },
    {
        id: EmailProviderIdEnum.SparkPost,
        displayName: 'SparkPost',
        channel: ChannelTypeEnum.EMAIL,
        credentials: sparkpostConfig,
        docReference: 'https://docs.novu.co/channels-and-providers/email/sparkpost',
        logoFileName: { light: 'sparkpost.svg', dark: 'sparkpost.svg' },
    },
    {
        id: EmailProviderIdEnum.EmailWebhook,
        displayName: 'Email Webhook',
        channel: ChannelTypeEnum.EMAIL,
        credentials: emailWebhookConfig,
        betaVersion: true,
        docReference: 'https://docs.novu.co/channels/email/email-webhook/',
        logoFileName: { light: 'email_webhook.svg', dark: 'email_webhook.svg' },
    },
];
