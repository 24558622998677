export var JobTopicNameEnum;
(function (JobTopicNameEnum) {
    JobTopicNameEnum["ACTIVE_JOBS_METRIC"] = "metric-active-jobs";
    JobTopicNameEnum["COMPLETED_JOBS_METRIC"] = "metric-completed-jobs";
    JobTopicNameEnum["INBOUND_PARSE_MAIL"] = "inbound-parse-mail";
    JobTopicNameEnum["STANDARD"] = "standard";
    JobTopicNameEnum["WEB_SOCKETS"] = "ws_socket_queue";
    JobTopicNameEnum["WORKFLOW"] = "trigger-handler";
    JobTopicNameEnum["PROCESS_SUBSCRIBER"] = "process-subscriber";
})(JobTopicNameEnum || (JobTopicNameEnum = {}));
export var ObservabilityBackgroundTransactionEnum;
(function (ObservabilityBackgroundTransactionEnum) {
    ObservabilityBackgroundTransactionEnum["JOB_PROCESSING_QUEUE"] = "job-processing-queue";
    ObservabilityBackgroundTransactionEnum["SUBSCRIBER_PROCESSING_QUEUE"] = "subscriber-processing-queue";
    ObservabilityBackgroundTransactionEnum["TRIGGER_HANDLER_QUEUE"] = "trigger-handler-queue";
    ObservabilityBackgroundTransactionEnum["WS_SOCKET_QUEUE"] = "ws_socket_queue";
})(ObservabilityBackgroundTransactionEnum || (ObservabilityBackgroundTransactionEnum = {}));
