export function Translate(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M9.16667 16L12.7292 6.5H14.4375L18 16H16.3542L15.5 13.5625H11.6667L10.8125 16H9.16667ZM12.1667 12.1875H15L13.625 8.25H13.5417L12.1667 12.1875ZM2.52083 13.5L1.47917 12.4375L5.47917 8.47917C4.97917 7.95139 4.51389 7.40278 4.08333 6.83333C3.65278 6.26389 3.27778 5.64583 2.95833 4.97917H4.66667C4.91667 5.42361 5.20139 5.84028 5.52083 6.22917C5.84028 6.61806 6.16667 7 6.5 7.375C7.02778 6.79167 7.51389 6.18403 7.95833 5.55208C8.40278 4.92014 8.76389 4.23611 9.04167 3.5H0V2H5.75V0H7.25V2H13V3.5H10.625C10.3333 4.45833 9.90972 5.34375 9.35417 6.15625C8.79861 6.96875 8.1875 7.74306 7.52083 8.47917L9.41667 10.3542L8.83333 11.8958L6.5 9.5625L2.52083 13.5Z"
        fill="#828299"
      />
    </svg>
  );
}
