export function Calendar(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M4.40377 10.6C3.95683 10.6 3.5778 10.4457 3.26669 10.1371C2.95558 9.82846 2.80002 9.45068 2.80002 9.00373C2.80002 8.55678 2.95433 8.17775 3.26294 7.86664C3.57154 7.55553 3.94932 7.39998 4.39627 7.39998C4.84322 7.39998 5.22225 7.55428 5.53336 7.86289C5.84447 8.17149 6.00002 8.54927 6.00002 8.99623C6.00002 9.44317 5.84572 9.8222 5.53711 10.1333C5.22851 10.4444 4.85073 10.6 4.40377 10.6ZM1.60002 13.4C1.27002 13.4 0.987524 13.2805 0.752524 13.0416C0.517524 12.8028 0.400024 12.5222 0.400024 12.2V3.39998C0.400024 3.07775 0.517524 2.7972 0.752524 2.55831C0.987524 2.31942 1.27002 2.19998 1.60002 2.19998H2.80002V0.599976H4.00002V2.19998H8.00002V0.599976H9.20002V2.19998H10.4C10.73 2.19998 11.0125 2.31942 11.2475 2.55831C11.4825 2.7972 11.6 3.07775 11.6 3.39998V12.2C11.6 12.5222 11.4825 12.8028 11.2475 13.0416C11.0125 13.2805 10.73 13.4 10.4 13.4H1.60002ZM1.60002 12.2H10.4V6.19998H1.60002V12.2ZM1.60002 4.99998H10.4V3.39998H1.60002V4.99998Z"
        fill="currentColor"
      />
    </svg>
  );
}
