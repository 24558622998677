/* eslint-disable max-len */
import React from 'react';

export function ProviderMissing(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="#E64545"
        d="M6.375 3c-.622 0-1.125.503-1.125 1.125V7.5H7.5V4.125C7.5 3.503 6.997 3 6.375 3zm6.75 0C12.503 3 12 3.503 12 4.125V7.5h2.25V4.125c0-.622-.503-1.125-1.125-1.125zm-9 5.625a1.124 1.124 0 100 2.25V12a5.628 5.628 0 004.5 5.512v2.363a1.124 1.124 0 102.25 0v-2.363a5.579 5.579 0 001.234-.404 6.195 6.195 0 014.366-7.12 1.124 1.124 0 00-1.1-1.364H4.125zM18.188 21a5.062 5.062 0 100-10.125 5.062 5.062 0 000 10.125zm2.084-6.353l-1.29 1.29 1.29 1.29a.565.565 0 010 .795.565.565 0 01-.794 0l-1.29-1.29-1.29 1.29a.565.565 0 01-.795 0 .565.565 0 010-.794l1.29-1.29-1.29-1.29a.565.565 0 010-.795.565.565 0 01.794 0l1.29 1.29 1.29-1.29a.565.565 0 01.795 0 .565.565 0 010 .794z"
      ></path>
    </svg>
  );
}
