import React from 'react';
/* eslint-disable */
export function Duplicate(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M10.6562 11.75H11.9688V9.34375H14.375V8.03125H11.9688V5.625H10.6562V8.03125H8.25V9.34375H10.6562V11.75ZM6.9375 14.375C6.57656 14.375 6.26758 14.2465 6.01055 13.9895C5.75352 13.7324 5.625 13.4234 5.625 13.0625V4.3125C5.625 3.95156 5.75352 3.64258 6.01055 3.38555C6.26758 3.12852 6.57656 3 6.9375 3H15.6875C16.0484 3 16.3574 3.12852 16.6145 3.38555C16.8715 3.64258 17 3.95156 17 4.3125V13.0625C17 13.4234 16.8715 13.7324 16.6145 13.9895C16.3574 14.2465 16.0484 14.375 15.6875 14.375H6.9375ZM6.9375 13.0625H15.6875V4.3125H6.9375V13.0625ZM4.3125 17C3.95156 17 3.64258 16.8715 3.38555 16.6145C3.12852 16.3574 3 16.0484 3 15.6875V5.625H4.3125V15.6875H14.375V17H4.3125Z"
        fill="currentColor"
      />
    </svg>
  );
}
